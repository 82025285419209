import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Map from "../components/map"
import SEO from "../components/seo"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const About = ({ data: { allContentfulAbout } }) => {
  const aboutData = allContentfulAbout.edges[0].node
  // prettier-ignore
  const mapData = [
        {
            "id": "1",
            "title": "United States",
            "shape": "poly",
            "name": "USA",
            "fillColor": "transparent",
            "strikeColor": "transparent",
            "lineWidth": "0",
            "coords" : [122, 303, 432, 296, 544, 342, 567, 412, 701, 325, 715, 328, 722, 363, 632, 492, 571, 561, 585, 629, 575, 633, 542, 572, 419, 582, 406, 621, 390, 615, 373, 580, 353, 571, 347, 580, 333, 572, 314, 546, 293, 550, 264, 551, 226, 534, 202, 538, 166, 502, 135, 450, 129, 430, 125, 313, 122, 303 ],
            "polygon" : [[122, 303], [432, 296], [544, 342], [567, 412], [701, 325], [715, 328], [722, 363], [632, 492], [571, 561], [585, 629], [575, 633], [542, 572], [419, 582], [406, 621], [390, 615], [373, 580], [353, 571], [347, 580], [333, 572], [314, 546], [293, 550], [264, 551], [226, 534], [202, 538], [166, 502], [135, 450], [129, 430], [125, 313], [122, 303] ],
            "logo": aboutData.usLogos.references
        },
        {
            "id": "2",
            "title": "Mexico",
            "shape": "poly",
            "name": "Mexico",
            "fillColor": "transparent",
            "strikeColor": "transparent",
            "lineWidth": "0",
            "coords" : [
                203, 540, 225, 537, 263, 554, 294, 554, 297, 549, 313, 549, 330, 569, 331, 574, 346, 585, 355, 573, 372, 582, 383, 602, 387, 617, 407, 626, 417, 702, 426, 715, 435, 721, 447, 719, 462, 717, 469, 714, 481, 703, 479, 691, 492, 682, 504, 679, 515, 681, 504, 694, 505, 703, 505, 719, 500, 717, 492, 724, 473, 724, 470, 735, 478, 745, 476, 746, 464, 747, 456, 759, 433, 745, 415, 751, 399, 738, 381, 734, 355, 721
            ],
            "polygon" : [[203, 540], [225, 537], [263, 554], [294, 554], [297, 549], [313, 549], [330, 569], [331, 574], [346, 585], [355, 573], [372, 582], [383, 602], [387, 617], [407, 626], [417, 702], [426, 715], [435, 721], [447, 719], [462, 717], [469, 714], [481, 703], [479, 691], [492, 682], [504, 679], [515, 681], [504, 694], [505, 703], [505, 719], [500, 717], [492, 724], [473, 724], [470, 735], [478, 745], [476, 746], [464, 747], [456, 759], [433, 745], [415, 751], [399, 738], [381, 734], [355, 721]],
            "logo": aboutData.mexicoLogos.references
        },
        {
            "id": "3",
            "title": "Ireland",
            "shape": "poly",
            "name": "Ireland",
            "fillColor": "transparent",
            "strikeColor": "transparent",
            "lineWidth": "0",
            "coords" : [1321, 219, 1333, 217, 1337, 204, 1352, 204, 1358, 201, 1368, 213, 1358, 222, 1360, 231, 1360, 248, 1357, 252, 1348, 252, 1343, 256, 1317, 256, 1328, 238, 1320, 230, 1321, 219 ],
            "polygon" : [[1321, 219], [1333, 217], [1337, 204], [1352, 204], [1358, 201], [1368, 213], [1358, 222], [1360, 231], [1360, 248], [1357, 252], [1348, 252], [1343, 256], [1317, 256], [1328, 238], [1320, 230], [1321, 219] ],
            "logo": aboutData.irelandLogos.references
        },
        {
            "id": "4",
            "title": "United Kingdom",
            "shape": "poly",
            "name": "United Kingdom",
            "fillColor": "transparent",
            "strikeColor": "transparent",
            "lineWidth": "0",
            "coords" : [1358, 164, 1376, 145, 1392, 140, 1405, 161, 1405, 165, 1394, 189, 1404, 190, 1435, 241, 1445, 247, 1440, 266, 1429, 273, 1371, 286, 1372, 257, 1382, 233, 1394, 232, 1387, 208, 1374, 209, 1368, 195, 1362, 180, 1358, 164],
            "polygon" : [[1358, 164], [1376, 145], [1392, 140], [1405, 161], [1405, 165], [1394, 189], [1404, 190], [1435, 241], [1445, 247], [1440, 266], [1429, 273], [1371, 286], [1372, 257], [1382, 233], [1394, 232], [1387, 208], [1374, 209], [1368, 195], [1362, 180], [1358, 164]],
            "logo": aboutData.ukLogos.references
        }
    ];
  return (
    <Layout>
      <SEO title="About" />
      <div className="about page-container">
        <div
          className="full-width-image"
          style={{
            backgroundImage: `url(${aboutData.aboutSammons.references[0].file.url})`,
          }}
        ></div>
        <div className="container-summary">
          {renderRichText(aboutData.ourObjectives, {
            renderNode: {
              [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="portfolio-header">{children}</h2>
              ),
              [BLOCKS.PARAGRAPH]: (node, children) => (
                <p className="section-summary align-center">{children}</p>
              ),
            },
          })}
        </div>
        <div className="blue-container">
          {aboutData.blueSection.references.map(block => (
            <div className="blue-block" key={block.id}>
              <div className="image-header">
                <img src={block.icon.file.url} alt={block.icon.file.fileName} />
                <h5>{block.sectionHeader}</h5>
              </div>
            </div>
          ))}
        </div>
        <div
          className="our-people-container full-width-image"
          style={{
            backgroundImage: `url(${aboutData.ourPeople.references[1].file.url})`,
          }}
        >
          {renderRichText(aboutData.ourPeople, {
            [BLOCKS.HEADING_2]: (node, children) => (
              <h2 className="home-header">{children}</h2>
            ),
            [BLOCKS.PARAGRAPH]: (node, children) => (
              <p className="section-summary align-center">{children}</p>
            ),
          })}
          <div className="image-container">
            <img
              className="shadow"
              src={aboutData.ourPeople.references[0].file.url}
              alt={aboutData.ourPeople.references[0].file.fileName}
            />
          </div>
        </div>
        <div className="worldwide-container">
          {renderRichText(aboutData.worldWide, {
            renderNode: {
              [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="portfolio-header">{children}</h2>
              ),
            },
          })}
          <Map mapData={mapData} />
        </div>
        <div className="health-welfare-container">
          {renderRichText(aboutData.healthAndWelfare, {
            renderNode: {
              [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="portfolio-header">{children}</h2>
              ),
            },
          })}
        </div>
      </div>
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  {
    allContentfulAbout {
      edges {
        node {
          aboutSammons {
            raw
            references {
              file {
                fileName
                url
              }
            }
          }
          ourObjectives {
            raw
          }
          ourPeople {
            references {
              file {
                url
                fileName
              }
            }
            raw
          }
          slug
          title
          id
          blueSection {
            references {
              id
              icon {
                file {
                  fileName
                  url
                }
              }
              text
              sectionHeader
            }
          }
          worldWide {
            raw
            references {
              file {
                url
                fileName
              }
            }
          }
          healthAndWelfare {
            raw
          }
          usLogos {
            raw
            references {
              description
              file {
                url
                fileName
              }
            }
          }
          mexicoLogos {
            raw
            references {
              description
              file {
                url
                fileName
              }
            }
          }
          ukLogos {
            raw
            references {
              description
              file {
                url
                fileName
              }
            }
          }
          irelandLogos {
            raw
            references {
              description
              file {
                url
                fileName
              }
            }
          }
        }
      }
    }
  }
`
