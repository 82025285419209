import React, { useState } from "react"
import WorldMap from "../../static/World_Map.jpg"
import Irland from "../../static/Rep-of-Ireland-Scroll-Over.jpg"
import Mexico from "../../static/Mexico-Scroll-Over.jpg"
import UnitedKingdom from "../../static/United-Kingdom-Scroll-Over.jpg"
import UnitedStates from "../../static/United-States-Scroll-Over.jpg"
import ImageMapper from "react-image-mapper"
import styled from "styled-components"

class Mapper extends React.Component {
  state = {
    img: WorldMap,
    modal: false,
    area: "",
  }
  enterArea = area => {
    if (area.id === "1") {
      this.setState({
        img: UnitedStates,
        modal: false,
      })
    } else if (area.id === "2") {
      this.setState({
        img: Mexico,
        modal: false,
      })
    } else if (area.id === "3") {
      this.setState({
        img: Irland,
        modal: false,
      })
    } else if (area.id === "4") {
      this.setState({
        img: UnitedKingdom,
        modal: false,
      })
    }
  }

  leaveArea = area => {
    this.setState({
      img: WorldMap,
    })
  }

  clicked = area => {
    if (area.id === "1") {
      this.setState({
        img: UnitedStates,
        modal: true,
        area: area,
      })
    } else if (area.id === "2") {
      this.setState({
        img: Mexico,
        modal: true,
        area: area,
      })
    } else if (area.id === "3") {
      this.setState({
        img: Irland,
        modal: true,
        area: area,
      })
    } else if (area.id === "4") {
      this.setState({
        img: UnitedKingdom,
        modal: true,
        area: area,
      })
    }
  }

  render() {
    const MAP = {
      name: "sammons",
      areas: this.props.mapData,
    }
    const ModalButton = styled.button`
      outline: none;
      border: none;
    `

    const MapBody = styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      overscroll-behavior: contain;
      align-items: center;
      text-align: center;
      height: 100vh;
      width: 100%;
      background: rgba(0, 0, 0, 0.8);
      position: fixed;
      top: 0;
      right: 0;
      transform: ${({ modal }) =>
        modal ? "translateX(0)" : "translateX(-100%)"};
      transition: transform 150ms;
      z-index: 9;
    `

    const ModalBody = styled.div`
      position: relative;
      background: white;
    `

    const CloseModal = styled.button`
      position: absolute;
      top: 15px;
      right: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 20px;
      height: 20px;
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      z-index: 10;

      div {
        width: 20px;
        height: 2px;
        border-radius: 8px;
        transform-origin: 4px;
        background: white;
        position: relative;
        transition: opacity 300ms, transform 300ms;

        :first-child {
          transform: rotate(45deg);
        }
        :nth-child(2) {
          transform: rotate(-45deg);
        }
      }
    `

    let windowWidth =
      typeof window !== "undefined"
        ? window.innerWidth < 768
          ? 418
          : 836
        : 836

    return (
      <>
        <div className="modal-container" key={this.id}>
          <MapBody modal={this.state.modal}>
            <ModalBody className="modal-body-map">
              <div className="profile-modal-header">
                <CloseModal
                  className="mobile-menu-button"
                  nav={this.state.modal}
                  onClick={() =>
                    this.setState({
                      img: WorldMap,
                      modal: false,
                    })
                  }
                >
                  <div></div>
                  <div></div>
                </CloseModal>
                <h3>{this.state.area.title}</h3>
              </div>
              {this.state.area.logo !== undefined ? (
                <div className="profile-modal-body-map">
                  {this.state.area.logo.map(x => (
                    <a href={x.description}>
                      <img
                        className="logo"
                        src={x.file.url}
                        alt={x.file.fileName}
                      />
                    </a>
                  ))}
                </div>
              ) : (
                <div></div>
              )}
            </ModalBody>
          </MapBody>
        </div>
        <div className="shadow map">
          <ImageMapper
            src={this.state.img}
            map={MAP}
            onClick={area => this.clicked(area)}
            onMouseEnter={area => this.enterArea(area)}
            onMouseLeave={area => this.leaveArea(area)}
            width={windowWidth}
            imgWidth={1520}
          />
        </div>
      </>
    )
  }
}

export default Mapper
